
import { SupplierInformationRequest } from "@Eikochain/__generated__/graphql";
import AddSupplierInformationRequestForm from "@Eikochain/components/ui/add-supplier-information-request-stepper-form/add-supplier-information-request-form";
import AddSupplierInformationRequestFormSuccess from "@Eikochain/components/ui/add-supplier-information-request-stepper-form/add-supplier-information-request-form/success";
import BaseLayout from "@Eikochain/layouts/BaseLayout";
import { useState } from "react";

export default function CreateSupplierInformationRequest() {
  const [submittedValues, setSubmittedValues] = useState<SupplierInformationRequest>();
  const breadcrumbs = [
    {
      name: "Suppliers",
      url: "/app/suppliers/"
    },
    {
      name: "Supplier information requests",
      url: "/app/suppliers/requests"
    },
    {
      name: "Create supplier request",
    },
  ]

  return (
    <BaseLayout breadcrumbs={breadcrumbs}>
      <div className="w-full px-4 py-16">
        <div className="w-full flex flex-col justify-center items-start max-w-[800px]">
          <div className="flex flex-col gap-2">
            <h2>Create supplier request</h2>
            <p>
              Use this form to request data from your suppliers, and it'll all be organised on the EikoIQ platform to easily perform carbon calculations with.
              You may want to use this to gather more accurate product information to work with, such as getting the weight of some material purchased.
            </p>
          </div>
          <div className="flex flex-col w-full mt-16">
            {
              submittedValues ? (
                <AddSupplierInformationRequestFormSuccess
                  forContact={submittedValues.forContact}
                  forSupplier={submittedValues.forSupplier}
                  onCreateNewRequest={() => setSubmittedValues(undefined)}
                />
              ) : <AddSupplierInformationRequestForm onSuccess={(values: SupplierInformationRequest) => setSubmittedValues(values)} />
            }
          </div>
        </div>
      </div>
    </BaseLayout>
  )
}
