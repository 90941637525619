import { SupplierInformationResponse } from "@Eikochain/__generated__/graphql";
import { toast } from "sonner";
import { AnyVariables, UseMutationExecute } from "urql";

export default function onSubmit (
  supplierRequestId: string,
  values: any,
  executionHandler: UseMutationExecute<any, AnyVariables>,
  onSuccess: (values: SupplierInformationResponse) => void,
): void {
  const data = {
    supplierInformationRequestId: supplierRequestId,
    name: values.name,
    weightPerUnitSpend: values.weightPerUnitSpend,
    country: values.country,
    document: values.documentUpload
  };

  executionHandler({
    input: data,
  }).then((result) => {
    const { data, error } = result;
    if (error) {
      toast.error("An error occured");
    } else {
      if (data.createSupplierInformationResponse?.errors) {
        toast.error(data.createSupplierInformationResponse?.errors?.[0]);
      } else {
        toast.success("Response for information sent");
        onSuccess(data.createSupplierInformationResponse?.supplierResponse);
      }
    }
  });
};
