import { gql, useQuery } from 'urql';

const GetSupplierInformationResponsesQuery = gql`
  query {
    supplierInformationResponses {
      id
      material
      country
      weight
      supplierName
      supplierInformationRequest {
        id
      }
    }
  }
`;

const useGetSupplierInformationResponses = () => {
  const result = useQuery({
    query: GetSupplierInformationResponsesQuery,
  })
  return result;
}

export default useGetSupplierInformationResponses;
