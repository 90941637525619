import { cn } from "@Eikochain/utils";
import { CheckIcon, ChevronDownIcon } from "lucide-react";
import { useState } from "react";
import { Button } from "./button";
import { Command, CommandEmpty, CommandInput, CommandItem, CommandList } from "./command";
import { FormControl } from "./form";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

interface ComboBoxProps {
  value?: any;
  options?: any[];
  valueDisplayKey?: string;
  noValueDisplayText: string;
  optionsSearchDisplayText: string;
  noOptionsDisplayText: string;
  onValueChange: (value: any) => void;
  disabled?: boolean;
}

export default function ComboBox({
  value,
  options,
  valueDisplayKey,
  noValueDisplayText,
  optionsSearchDisplayText,
  noOptionsDisplayText,
  onValueChange,
  disabled = false
}: ComboBoxProps) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  return (
    <Popover open={popoverOpen} onOpenChange={setPopoverOpen} modal>
      <PopoverTrigger asChild>
        <FormControl>
          <Button
            variant="outline"
            role="combobox"
            className={cn(
              "justify-between font-normal",
              !value && "text-muted-foreground"
            )}
            disabled={disabled}
          >
            <span className="text-ellipsis overflow-hidden">
              {!value ? noValueDisplayText : (valueDisplayKey ? value?.[valueDisplayKey] : value)}
            </span>
            <ChevronDownIcon className="ml-2 h-4 w-4 flex-shrink-0" />
          </Button>
        </FormControl>
      </PopoverTrigger>
      <PopoverContent className="w-[--radix-popover-trigger-width] max-h-[--radix-popover-content-available-height] p-0">
        <Command>
          <CommandInput placeholder={optionsSearchDisplayText} />
          <CommandList>
            <CommandEmpty>{noOptionsDisplayText}</CommandEmpty>
            {
              options?.map((option: any) => {
                const optionValue = valueDisplayKey ? option?.[valueDisplayKey] : option;
                return (
                  <CommandItem
                    key={optionValue}
                    value={optionValue}
                    onSelect={() => {
                      onValueChange(option);
                      setPopoverOpen(false)
                    }}
                  >
                    {optionValue}
                    <CheckIcon
                      className={cn(
                        "ml-auto h-4 w-4",
                        optionValue === value
                          ? "opacity-100"
                          : "opacity-0"
                      )}
                    />
                  </CommandItem>
                )
              })
            }
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
