
import * as yup from "yup";

export const supplierInformationRequestFormSchema = yup.object().shape({
  companyName: yup.string().required("Required"),
  country: yup.string().required("Required"),
  weightPerUnitSpend: yup.number().min(0.0001, "Must be at least 0.0001").required("Required"),
  documentUpload: yup.mixed()
    .test(
      "fileSize", "The file is too large, cannot be larger than 2MB", value => {
        if (!(value as File)?.size) return true
        return (value as File).size <= 2000000
      }
    ).test(
      "fileFormat", "The file format must be one of PNG, JPEG or PDF", (value) => {
        const supportedFormats = ['pdf', 'png', 'jpeg'];
        if (value) {
          const splitValue = ((value as File).type).split("/").at(-1) as string
          return supportedFormats.includes(splitValue)
        }
        return true
      }
    ).when(
     "$requireProof", ([requireProof], schema) => {
      if (requireProof === true) {
        return schema.required("File has been marked as required by the customer")
      }
      return schema
    }),
});
