import { CreateSupplierMutation } from "@Eikochain/graphql/hooks/mutations/create-supplier"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { toast } from "sonner"
import { useMutation } from "urql"
import { InferType } from "yup"
import { Button } from "../button"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../form"
import { Input } from "../input"
import { addSupplierFormSchema } from "./schema"

interface AddSupplierFormProps {
  onSuccess: () => void;
}

export default function AddSupplierForm({
  onSuccess
}: AddSupplierFormProps) {
  const [mutationResult, executeMutation] = useMutation(CreateSupplierMutation);
  const form = useForm({
    resolver: yupResolver(addSupplierFormSchema),
    defaultValues: {
      supplierName: "",
      primaryContactName: "",
      primaryContactEmail: "",
    }
  })

  function onSubmit (values: InferType<typeof addSupplierFormSchema>) {
    executeMutation({
      input: {
        supplier: values
      }
    }).then((result) => {
      if (result.data?.createSupplier) {
        toast.success(`Added ${result.data?.createSupplier?.supplier?.name} as a supplier`)
        onSuccess()
      }
    });
  };

  return (
    <Form {...form}>
      <form
        className="space-y-6"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name="supplierName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                Supplier company name
              </FormLabel>
              <FormControl>
                <Input placeholder="e.g. Acme Ltd., Logistics Co." {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="primaryContactName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                Primary contact name
              </FormLabel>
              <FormControl>
                <Input placeholder="e.g. John" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="primaryContactEmail"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                Primary contact email
              </FormLabel>
              <FormControl>
                <Input type="email" placeholder="e.g. john@acme.org" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex flex-row gap-2 justify-end">
          <Button type="submit" loading={mutationResult?.fetching}>Add supplier</Button>
        </div>
      </form>
    </Form>
  )
}
