import { gql, useQuery } from 'urql';

const GetHighestEmittingProductsQuery = gql`
  query {
    highestEmittingProducts {
      djangoId
      name
      description
      url
      emissionsBreakdown {
        category
          emissions {
            results {
              co2e
            }
          }
        }
      }
    }
  }
`;

const useGetHighestEmittingProducts = () => {
  const result = useQuery({
    query: GetHighestEmittingProductsQuery,
  })
  return result;
}

export default useGetHighestEmittingProducts;
