import { gql } from 'urql';

export const CreateSupplierMutation = gql`
  mutation CreateSupplier($input: CreateSupplierInput!) {
    createSupplier(input: $input) {
      ... on CreateSupplierSuccess {
        supplier {
          id
          name
        }
      }
      ... on CreateSupplierErrors {
        errors
      }
    }
  }
`;
