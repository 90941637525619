import { gql } from 'urql';

export const CreateSupplierInformationResponseMutation = gql`
  mutation CreateSupplierInformationResponse($input: CreateSupplierInformationResponseInput!) {
    createSupplierInformationResponse(input: $input) {
      ... on CreateSupplierInformationResponseSuccess {
        supplierResponse {
          id
          supplierInformationRequest {
            submittedByOrganization {
              name
            }
          }
        }
      }
      ... on CreateSupplierInformationResponseErrors {
        errors
      }
    }
  }
`;
