import ScopedActivityMetrics from "@Eikochain/components/ui/scoped-activity-metrics";
import ScopedActivityStepsSection from "@Eikochain/components/ui/scoped-activity-steps-section";
import BaseLayout from "@Eikochain/layouts/BaseLayout";

export default function CompanyCalculator () {
  const breadcrumbs = [{
    name: "Scope Emissions Calculator"
  }]
  return (
    <BaseLayout breadcrumbs={breadcrumbs}>
      <div className="mt-6 flex flex-col p-4 gap-8 h-full">
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-6 md:gap-12">
            <ScopedActivityMetrics />
            <ScopedActivityStepsSection />
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};
