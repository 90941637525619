import { Checkbox } from "@Eikochain/components/ui/checkbox";
import { FormControl, FormDescription, FormField, FormItem, FormLabel } from "@Eikochain/components/ui/form";
import { Control } from "react-hook-form";

interface RequireProofCheckboxFieldProps {
  control: Control<any>;
}

export default function RequireProofCheckboxField({
  control,
}: RequireProofCheckboxFieldProps) {
  return (
    <FormField
      control={control}
      name="requireProof"
      render={({ field }) => (
        <FormItem className="flex flex-row items-center space-x-6 space-y-2">
          <FormControl>
            <Checkbox
              checked={field.value}
              onCheckedChange={field.onChange}
            />
          </FormControl>
          <div className="space-y-1 leading-none">
            <FormLabel>Require document proof?</FormLabel>
            <FormDescription>
              Your supplier will be required to upload a certificate, invoice or document that confirms their entry
            </FormDescription>
          </div>
        </FormItem>
      )}
    />
  )
}
