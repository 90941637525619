import { gql, useQuery } from 'urql';

const GetSupplierInformationRequestByIdQuery = gql`
  query($id: String!) {
    supplierInformationRequestById(id: $id) {
      id
      status
      createdAt
      forSupplier {
        name
      }
      forContact {
        name
        email
      }
      submittedByOrganization {
        name
      }
      submittedByUser {
        email
      }
      material
      additionalInformation
      requireProof
      requestType
      response {
        weight
        createdAt
        country
        documents {
          location
        }
      }
    }
  }
`;

const useGetSupplierInformationRequestById = (id: string) => {
  const result = useQuery({
    query: GetSupplierInformationRequestByIdQuery,
    variables: {
      id
    }
  })
  return result;
}

export default useGetSupplierInformationRequestById;
