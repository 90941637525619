import UploadedFileList from "@Eikochain/components/molecules/UploadedFileList";
import { Button } from "@Eikochain/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@Eikochain/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@Eikochain/components/ui/form";
import { Input } from "@Eikochain/components/ui/input";
import { CreateShippingCalculationsFromCsvMutation } from "@Eikochain/graphql/hooks/mutations/useCreateShippingCalculationsFromCsv";
import BaseLayout from "@Eikochain/layouts/BaseLayout";
import { yupResolver } from "@hookform/resolvers/yup";
import { DownloadIcon, UploadIcon } from "lucide-react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { useMutation } from "urql";
import * as yup from "yup";
import { csvUploadSchema } from "./schemas";

type Inputs = {
  csvFile: any;
};

const ShippingCalculator = () => {
  const breadcrumbs = [{
    name: "Shipping calculator"
  }]
  const navigate = useNavigate();
  const [mutation, executeMutation] = useMutation(CreateShippingCalculationsFromCsvMutation);

  const form = useForm<yup.InferType<typeof csvUploadSchema>>({
    resolver: yupResolver(csvUploadSchema),
  });

  const onSubmit: SubmitHandler<Inputs> = (data: Inputs) => {
    const input = { csvFile: data.csvFile };
    executeMutation({ input }).then((result) => {
      const { data } = result;
      if (data?.bulkUploadActivitiesFromCsv?.success) {
        toast.success(
          "Uploaded, wait a moment before refreshing the page to see results",
          {
            duration: 4000
          }
        );
        navigate("/app/uploads/detail/" + data?.bulkUploadActivitiesFromCsv?.uploadId);
      } else {
        toast.error("We encountered an error and we're looking into it");
      }
    });
  };

  return (
    <BaseLayout breadcrumbs={breadcrumbs}>
      <div className="flex flex-col p-4 gap-8">
        <div className="flex justify-end gap-2">
          <div className="flex gap-2">
            <a href="/EikoIqOrderUploadTemplate.csv">
              <Button className="flex gap-2" variant="ghost">
                <DownloadIcon />
                Download CSV template
              </Button>
            </a>
            <Dialog>
              <DialogTrigger>
                <Button className="flex gap-2">
                  <UploadIcon />
                  Upload new CSV
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Upload a CSV file</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                  <form className="flex flex-col gap-8" onSubmit={form.handleSubmit(onSubmit)}>
                    <FormField
                      control={form.control}
                      name="csvFile"
                      render={({ field: { onChange }, ...field }) => (
                        <FormItem>
                          <FormLabel>File</FormLabel>
                          <FormControl>
                            <Input
                              id="csvFile"
                              type="file"
                              accept=".csv"
                              {...field}
                              onChange={(e) => onChange(e.target.files ? e.target.files[0] : null)}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <Button type="submit" loading={mutation.fetching}>
                      Upload and calculate emissions
                    </Button>
                  </form>
                </Form>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
      <div className="p-2">
        <UploadedFileList />
      </div>
    </BaseLayout>
  );
};

export default ShippingCalculator;
