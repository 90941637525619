import useLogout from "@Eikochain/hooks/useLogout";
import { userAtom } from "@Eikochain/state/atoms/UserAtom";
import { useAtomValue } from "jotai";
import { KeyIcon, LogOutIcon, ShieldEllipsisIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import { Avatar, AvatarFallback } from "./avatar";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "./dropdown-menu";

interface AccountCardProps {
    isCollapsed: boolean;
}

export default function AccountCard({ }: AccountCardProps) {
    const user = useAtomValue(userAtom);
    const firstNamesLetter = user?.email?.charAt(0).toUpperCase() ?? "U";
    const logout = useLogout();

    const signOutClicked = () => {
        logout();
        toast.success("See you again soon!")
    };

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <div className="flex w-fit items-center justify-between rounded-lg cursor-pointer text-sm p-2">
                    <div className="flex items-center gap-4">
                        <Avatar>
                            <AvatarFallback className="bg-indigo-400 text-white">{firstNamesLetter}</AvatarFallback>
                        </Avatar>
                    </div>
                </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="gap-6">
                <div className="p-4">
                    {
                        user?.firstNames ? <p className="font-bold">Good day {user.firstNames} &#128075;</p> : <p className="font-bold">{user?.organization?.name}</p>
                    }
                    <p>{user?.email}</p>
                </div>
                <DropdownMenuItem asChild>
                    <Link to={"/app/ChangePassword"}>
                        <KeyIcon />
                        Update password
                    </Link>
                </DropdownMenuItem>
                {user?.isStaff && (
                    <DropdownMenuItem asChild>
                        <Link to={`${import.meta.env.VITE_DJANGO_API_URL}/admin/`}>
                            <ShieldEllipsisIcon />
                            Admin panel
                        </Link>
                    </DropdownMenuItem>
                )}
                <DropdownMenuItem onClick={signOutClicked}>
                    <LogOutIcon />
                    Log Out
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
