import { Supplier, SupplierContact } from "@Eikochain/__generated__/graphql";
import * as yup from "yup";

export const addSupplierFormSchema = yup.object().shape({
  supplier: yup.object<Supplier>().shape({
    id: yup.string().required(),
    name: yup.string().required("Supplier required")
  }).default(undefined).required("Supplier required"),
  contact: yup.object<SupplierContact>().shape({
    id: yup.string().required(),
    email: yup.string().email().required("Contact email required"),
  }).default(undefined).required("Contact email required"),
  material: yup.string().required("Material required"),
  additionalInformation: yup.string().optional(),
  supplierInformationRequestType: yup.string().required("Request type is required"),
  requireProof: yup.bool()
});
