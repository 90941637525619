import { SupplierInformationResponse } from "@Eikochain/__generated__/graphql";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown } from "lucide-react";
import { Button } from "../button";

const columns: ColumnDef<SupplierInformationResponse>[] = [
  {
    accessorKey: "supplierName",
    header: "Supplier",
    cell: ({ row }) => (
      <div className="flex flex-row items-center gap-4">
        <span>{row.getValue("supplierName")}</span>
      </div>
    ),
  },
  {
    id: "material",
    header: ({ column }) => {
      return (
        <Button className="p-0" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          Material
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => (
      <div className="flex flex-row items-center gap-4">
        <span>{row.original.material}</span>
      </div>
    )
  },
  {
    id: "weight",
    header: ({ column }) => {
      return (
        <Button className="p-0" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          Weight (kg)
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => (
      <div className="flex flex-row items-center gap-4">
        <span>{row.original.weight}</span>
      </div>
    ),
  },
]

export default columns;
