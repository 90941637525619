import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@Eikochain/components/ui/resizable";
import { Sheet, SheetContent, SheetTrigger } from "@Eikochain/components/ui/sheet";
import SideNav from "@Eikochain/components/ui/side-navigation";
import WorkspaceControlBar, { BreakcrumbProps } from "@Eikochain/components/ui/workspace-control-bar";
import { PanelLeftOpenIcon } from "lucide-react";
import { ReactNode, useState } from "react";

interface BaseLayoutProps {
    breadcrumbs: BreakcrumbProps[];
    description?: string;
    children?: ReactNode;
    defaultLayout?: number[] | undefined;
}

export default function BaseLayout({
    breadcrumbs,
    description,
    children,
    defaultLayout = [20, 80],
}: BaseLayoutProps) {
    const [isCollapsed, setIsCollapsed] = useState(false)
    return (
        <div className="h-full">
            <Sheet>
                <SheetTrigger asChild>
                    <div className="p-4 lg:hidden">
                        <PanelLeftOpenIcon size={36} />
                    </div>
                </SheetTrigger>
                <SheetContent className="p-0" side="left">
                    <SideNav isCollapsed={false} />
                </SheetContent>
            </Sheet>
            <ResizablePanelGroup
                direction="horizontal"
                className="h-screen"
            >
                <ResizablePanel
                    defaultSize={defaultLayout[0]}
                    className="hidden lg:flex"
                    collapsedSize={5}
                    collapsible={true}
                    minSize={15}
                    maxSize={defaultLayout[0]}
                    id="sideNavigationPane"
                    order={1}
                    onCollapse={() => setIsCollapsed(true)}
                    onExpand={() => setIsCollapsed(false)}
                >
                    <SideNav isCollapsed={isCollapsed} />
                </ResizablePanel>
                <ResizableHandle
                    withHandle
                    className="hidden lg:flex"
                />
                <ResizablePanel defaultSize={defaultLayout[1]} minSize={defaultLayout[1]} id="workspacePane" order={2}>
                    <div className="flex flex-col h-screen overflow-scroll px-4 py-6 bg-white">
                        <WorkspaceControlBar breadcrumbs={breadcrumbs} description={description} />
                        <div className="mb-12">{children}</div>
                    </div>
                </ResizablePanel>
            </ResizablePanelGroup>
        </div>
    );
};
