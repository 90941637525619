import { BlocksIcon, BriefcaseIcon, DatabaseIcon, FileBoxIcon, LayoutIcon, PackageSearchIcon, StoreIcon, TruckIcon } from "lucide-react";
import HandshakeIcon from "./components/svg/handshake-icon";

export const fieldValueOptionsForShippingMethod = [
  {
    value: "Air Freight",
    label: "Air Freight",
  },
  {
    value: "Rail Freight",
    label: "Rail Freight",
  },
  {
    value: "Road Freight",
    label: "Road Freight",
  },
  {
    value: "Sea Freight",
    label: "Sea Freight",
  },
];

export const NAV_OPTIONS = [
  {
    name: "Dashboard",
    children: [{
      href: "/app/dashboard/",
      icon: LayoutIcon,
      name: "Dashboard",
      description: "Overview of your emissions and insights",
      disabled: false
    }]
  },
  {
    name: "Products",
    children: [
      {
        href: "/app/products/",
        icon: StoreIcon,
        name: "Products",
        description: "Your catalogue",
        disabled: false
      },
      {
        href: "/app/integrations/",
        icon: BlocksIcon,
        name: "Integrations",
        description: "Ecommerce and shipping integrations",
        disabled: false
      }
    ]
  },
  {
    name: "Calculators",
    children: [
      {
        href: "/app/product-calculator/",
        icon: PackageSearchIcon,
        name: "Product Calculator",
        description: "Perform LCA's for individual products",
        disabled: false
      },
      {
        href: "/app/company-calculator/",
        icon: BriefcaseIcon,
        name: "Scope Emissions Calculator",
        description: "Scopes 1, 2 and 3 emissions for your org",
        disabled: false
      },
      {
        href: "/app/shipping-calculator/",
        icon: TruckIcon,
        name: "Shipping Calculator",
        description: "Bulk calculate product orders",
        disabled: false
      }
    ]
  },
  {
    name: "Data",
    children: [
      {
        href: "/app/reporting/",
        icon: FileBoxIcon,
        name: "Reporting",
        description: "Export your activities and emissions data",
        disabled: false,
      },
      {
        href: "/app/data-explorer/",
        icon: DatabaseIcon,
        name: "Data Explorer",
        description: "All of our emission factors",
        disabled: false
      },
      {
        href: "/app/suppliers/",
        icon: HandshakeIcon,
        name: "Suppliers",
        description: "Request data from your suppliers",
        disabled: false,
      },
    ]
  },
];
