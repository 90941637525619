import { Supplier } from "@Eikochain/__generated__/graphql";
import MaterialField from "@Eikochain/components/ui/create-update-activity-dialog/fields/material-field";
import useEmissionFactorsByActivityType from "@Eikochain/graphql/hooks/useEmissionFactorsByActivityType";
import useGetSuppliers from "@Eikochain/graphql/hooks/useGetSuppliers";
import { useEffect, useMemo } from "react";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";
import RequireProofCheckboxField from "../fields/require-proof-checkbox-field";
import SupplierAdditionalInformationField from "../fields/supplier-additional-information-field";
import SupplierContactField from "../fields/supplier-contact-field";
import SupplierInformationRequestTypeField from "../fields/supplier-information-request-type-field";
import SupplierNameField from "../fields/supplier-name-field";
import { useParams } from "react-router-dom";

interface SupplierInfoFormProps {
    control: Control<any>;
    setValue: UseFormSetValue<any>;
}

export default function SupplierInfoForm({
    control,
    setValue
}: SupplierInfoFormProps) {
    const [result] = useGetSuppliers()
    const { id: slugId } = useParams<{ id: string }>()
    const selectedSupplier = useWatch({ control, name: "supplier" }) as Supplier;
    const [emissionFactors] = useEmissionFactorsByActivityType("process")
    const materialsCategories = useMemo(() => {
        return emissionFactors?.data?.emissionFactorsByActivityType?.categoriesMaterialsProcesses
    }, [emissionFactors?.data])

    useEffect(() => {
        if (!slugId || result.fetching || !result.data) return

        const supplier: Supplier | null = result.data.suppliers.find((supplier: Supplier) => supplier.id === slugId)
        if (supplier) setValue("supplier", supplier)
    }, [result])

    return (
        <>
            <SupplierNameField control={control} setValue={setValue} suppliers={result.data?.suppliers} />
            <SupplierContactField control={control} setValue={setValue} contacts={selectedSupplier?.contacts ?? []} />
            <SupplierInformationRequestTypeField control={control} />
            <MaterialField control={control} setValue={setValue} materialsCategories={materialsCategories} />
            <SupplierAdditionalInformationField control={control} />
            <RequireProofCheckboxField control={control} />
        </>
    )
}
