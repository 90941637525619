import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@Eikochain/components/ui/form";
import { Textarea } from "@Eikochain/components/ui/textarea";
import { Control } from "react-hook-form";

interface SupplierAdditionalInformationFieldProps {
  control: Control<any>;
}

export default function SupplierAdditionalInformationField({
  control
}: SupplierAdditionalInformationFieldProps) {
  return (
    <FormField
      control={control}
      name="additionalInformation"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Additional information</FormLabel>
          <FormDescription>
            Any additional info you would like the receiver of the request to be aware of
          </FormDescription>
          <FormControl>
            <Textarea {...field} placeholder="e.g. Refer to a specific invoice period or the grade of product" />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
