import { Supplier } from "@Eikochain/__generated__/graphql";
import ComboBox from "@Eikochain/components/ui/combobox";
import { Control, UseFormSetValue } from "react-hook-form";
import { FormField, FormItem, FormLabel, FormMessage } from "../../../form";

interface SupplierNameFieldProps {
    control: Control<any>;
    setValue: UseFormSetValue<any>;
    suppliers: Supplier[]
}

export default function SupplierNameField({
    control,
    setValue,
    suppliers,
}: SupplierNameFieldProps) {
    return (
        <FormField
            control={control}
            name="supplier"
            render={({ field }) => {
                return (
                    <FormItem>
                        <FormLabel>Supplier</FormLabel>
                        <ComboBox
                            value={field.value}
                            optionsSearchDisplayText="Search suppliers..."
                            options={suppliers}
                            valueDisplayKey="name"
                            noOptionsDisplayText="No suppliers found"
                            noValueDisplayText="Select supplier"
                            onValueChange={(supplier) => setValue("supplier", supplier)}
                        />
                        <FormMessage />
                    </FormItem>
                )
            }}
        />
    )
}
