import { cn } from "@Eikochain/utils";
import type { ICountry } from 'countries-list';
import { countries } from 'countries-list';
import { CheckIcon, ChevronDownIcon } from "lucide-react";
import { useState } from "react";
import { Control, UseFormSetValue } from "react-hook-form";
import { Button } from "../../button";
import { Command, CommandEmpty, CommandInput, CommandItem, CommandList } from "../../command";
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "../../form";
import { Popover, PopoverContent, PopoverTrigger } from "../../popover";

interface CountryFieldProps {
  control: Control<any>;
  setValue: UseFormSetValue<any>;
}

export default function CountryField({
  control,
  setValue
}: CountryFieldProps) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const countryValues = Object.values(countries);
  
  return (
    <FormField
      control={control}
      name="country"
      render={({ field }) => {
        return (
          <FormItem>
            <FormLabel>In which country was the product produced?</FormLabel>
            <FormDescription>Please select from the list of countries below</FormDescription>
            <Popover open={popoverOpen} onOpenChange={setPopoverOpen} modal>
              <PopoverTrigger asChild>
                <FormControl>
                  <Button
                    variant="outline"
                    role="combobox"
                    className={cn(
                      "justify-between font-normal",
                      !field.value && "text-muted-foreground"
                    )}
                  >
                    <span className="text-ellipsis overflow-hidden">
                      {field.value ?? "Select country"}
                    </span>
                    <ChevronDownIcon className="ml-2 h-4 w-4 flex-shrink-0" />
                  </Button>
                </FormControl>
              </PopoverTrigger>
              <PopoverContent className="w-[--radix-popover-trigger-width] max-h-[--radix-popover-content-available-height] p-0">
                <Command>
                  <CommandInput placeholder="Search countries..." />
                  <CommandList>
                    <CommandEmpty>No contacts found</CommandEmpty>
                    {
                      countryValues?.map((country: ICountry) => (
                        <CommandItem
                          key={country.name}
                          value={country.name}
                          onSelect={() => {
                            setValue("country", country.name);
                            setPopoverOpen(false)
                          }}
                        >
                          {country.name}
                          <CheckIcon
                            className={cn(
                              "ml-auto h-4 w-4",
                              country.name === field.value
                                ? "opacity-100"
                                : "opacity-0"
                            )}
                          />
                        </CommandItem>
                      ))
                    }
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
            <FormMessage />
          </FormItem>
        )
      }}
    />
  )
}
