import { Supplier } from "@Eikochain/__generated__/graphql";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown } from "lucide-react";
import { Button } from "../button";
import SupplierActionsDropdownMenu from "./supplier-actions-dropdown-menu";

const columns: ColumnDef<Supplier>[] = [
  {
    id: "supplierName",
    header: ({ column }) => {
      return (
        <Button className="p-0" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          Supplier name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => (
      <div className="flex flex-row items-center gap-4">
        <span>{row.original.name}</span>
      </div>
    ),
  },
  {
    id: "actions",
    header: "Actions",
    cell: ({ row }) => (
      <SupplierActionsDropdownMenu supplierId={row.original.id} />
    ),
    enableSorting: false,
    enableHiding: false,
  },
]

export default columns;
