import AccountCard from "@Eikochain/components/ui/account-card";
import Logo from "@Eikochain/components/ui/logo";
import { NAV_OPTIONS } from "@Eikochain/constants";
import { cn } from "@Eikochain/utils";
import { MessagesSquareIcon } from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "./button";

interface SideNavProps {
    isCollapsed: boolean;
}

export default function SideNav({
    isCollapsed
}: SideNavProps) {
    const location = useLocation();
    return (
        <div className="h-full flex-col justify-between z-[1] w-full bg-indigo-50 flex">
            <div className="flex flex-col w-full">
                <div className="flex w-full justify-center py-4 px-4 gap-2 items-center">
                    <Logo className="max-w-[150px] h-fit" />
                    <div className="flex-grow flex justify-end">
                        <AccountCard isCollapsed={isCollapsed} />
                    </div>
                </div>
                {NAV_OPTIONS.map((item, index) => (
                    <div key={index} className="flex flex-col pb-4 text-sm">
                        {
                            !isCollapsed && <span className="uppercase px-5 pb-2 font-semibold text-indigo-800 whitespace-nowrap">{item.name}</span>
                        }
                        {item.children?.map((child, childIndex) => (
                            <Link
                                key={childIndex}
                                to={child.disabled ? "/app/" : child.href}
                                className={cn(
                                    child.disabled && "pointer-events-none cursor-not-allowed opacity-40"
                                )}
                            >
                                <span
                                    className={cn(
                                        "group w-full flex items-center text-xs font-medium hover:bg-indigo-200 hover:text-accent-foreground transition-colors ease-in-out",
                                        location.pathname === child.href ? "bg-indigo-300" : "transparent",
                                        isCollapsed ? "px-2 py-2 justify-center" : "px-5 py-2"
                                    )}
                                >
                                    <child.icon size={20} />
                                    {
                                        !isCollapsed && (
                                            <div className='ml-4'>
                                                <span className="flex flex-col lg:flex-row text-base gap-4">
                                                    {child.name}
                                                </span>
                                            </div>
                                        )
                                    }
                                </span>
                            </Link>
                        ))}
                    </div>
                ))}
            </div>
            <div className="flex flex-col w-full gap-4">
                <div className="w-full flex flex-col md:flex-row items-center flex-wrap">
                    <Link to="https://eikoiq.canny.io/feature-requests" target="_blank">
                        <Button variant="link" icon={<MessagesSquareIcon />}>
                            {!isCollapsed && "Report issue/feedback"}
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};
