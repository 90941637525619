import { SupplierContact } from "@Eikochain/__generated__/graphql";
import ComboBox from "@Eikochain/components/ui/combobox";
import { useEffect } from "react";
import { Control, UseFormSetValue } from "react-hook-form";
import { FormField, FormItem, FormLabel, FormMessage } from "../../../form";

interface SupplierContactFieldProps {
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  contacts: SupplierContact[]
}

export default function SupplierContactField({
  control,
  setValue,
  contacts,
}: SupplierContactFieldProps) {
  useEffect(() => {
    setValue("contact", contacts?.[0])
  }, [contacts])
  return (
    <FormField
      control={control}
      name="contact"
      render={({ field }) => {
        return (
          <FormItem>
            <FormLabel>Contact email</FormLabel>
            <ComboBox
              value={field.value}
              optionsSearchDisplayText="Search contacts..."
              options={contacts}
              valueDisplayKey="email"
              noOptionsDisplayText="No contacts found"
              noValueDisplayText="Select contact"
              disabled={contacts.length <= 1}
              onValueChange={(contact) => setValue("contact", contact)}
            />
            <FormMessage />
          </FormItem>
        )
      }}
    />
  )
}
