import { Supplier, SupplierContact } from "@Eikochain/__generated__/graphql";
import { CheckCheckIcon, PlusIcon } from "lucide-react";
import { Button } from "../../button";

interface AddSupplierInformationRequestFormSuccessProps {
  forContact: SupplierContact;
  forSupplier: Supplier;
  onCreateNewRequest: () => void;
}

export default function AddSupplierInformationRequestFormSuccess({
  forContact,
  forSupplier,
  onCreateNewRequest,
}: AddSupplierInformationRequestFormSuccessProps) {
  return (
    <div className="flex flex-col items-center justify-center gap-4">
      <div className="flex items-center justify-center rounded-full p-2 bg-emerald-100">
        <div className="flex items-center justify-center rounded-full h-16 w-16 bg-emerald-700">
          <CheckCheckIcon className="text-white" />
        </div>
      </div>
      <h3 className="text-center">Sent information request to {forContact.email} at {forSupplier.name}</h3>
      <Button variant="link" icon={<PlusIcon />} onClick={onCreateNewRequest}>Create another supplier request</Button>
    </div>
  )
}
