import { Control } from "react-hook-form";
import { FormDescription, FormField, FormItem, FormLabel, FormMessage } from "../../../form";
import { RadioButtonGroup, RadioGroupItemButton } from "../../../radio-group";

interface SupplierInformationRequestTypeFieldProps {
    control: Control<any>;
    disabled?: boolean;
}

const supplierInformationRequestTypes = [
    {
        value: "material_weight",
        label: "Weight of material purchased"
    },
]

export default function SupplierInformationRequestTypeField({
    control,
    disabled = false,
}: SupplierInformationRequestTypeFieldProps) {
    return (
        <FormField
            control={control}
            name="supplierInformationRequestType"
            render={({ field }) => (
                <FormItem>
                    <FormLabel>Request type</FormLabel>
                    <FormDescription>Say what type of information you're requesting from your supplier</FormDescription>
                    <RadioButtonGroup {...field} value={field.value} onValueChange={field.onChange}>
                        {supplierInformationRequestTypes.map(supplierInformationRequestType => (
                            <RadioGroupItemButton
                                value={supplierInformationRequestType.value}
                                label={supplierInformationRequestType.label}
                                disabled={disabled}
                            />
                        ))}
                    </RadioButtonGroup>
                    <FormMessage />
                </FormItem>
            )}
        />
    )
}
