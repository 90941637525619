import ReportsTable from "@Eikochain/components/ui/reports-table";
import useGetReports from "@Eikochain/graphql/hooks/useGetReports";
import BaseLayout from "@Eikochain/layouts/BaseLayout";

export default function ReportingPage() {
  const [result] = useGetReports();
  const breadcrumbs = [{
    name: "Reporting"
  }]

  return (
    <BaseLayout
      breadcrumbs={breadcrumbs}
      description="All your generated reports will be visible here. Generate reports from the scope emissions or shipping calculators"
    >
      <div className="w-full py-2">
        <ReportsTable
          data={result.data?.reports ?? []} isFetching={result?.fetching}
        />
      </div>
    </BaseLayout>
  )
}
