import HandshakeIcon from "@Eikochain/components/svg/handshake-icon";
import { VariableIcon } from "lucide-react";
import EmissionFactorsTableWithData from "../emission-factors-table/with-data-wrapper";
import SupplierInformationResponsesTableWithData from "../supplier-information-responses-table/with-data-wrapper";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../tabs";

export default function DataExplorerAddon() {
  return (
    <Tabs defaultValue="emission-activities">
      <TabsList>
        <TabsTrigger value="emission-activities"><VariableIcon />Emission activities</TabsTrigger>
        <TabsTrigger value="supplier"><HandshakeIcon />Supplier data</TabsTrigger>
      </TabsList>
      <TabsContent value="emission-activities">
        <EmissionFactorsTableWithData
          showColumnToggler={false}
          showRowCount={false}
          onRowClick={
            (values) => window.open(
              `/app/data-explorer/emission-activity/${values.id}`, '_blank', 'rel=noopener noreferrer'
            )
          }
        />
      </TabsContent>
      <TabsContent value="supplier">
        <SupplierInformationResponsesTableWithData />
      </TabsContent>
    </Tabs>
  )
}
