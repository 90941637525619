import {
    ColumnFiltersState,
    SortingState,
    VisibilityState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table"
import * as React from "react"
import { useEffect } from "react"

import { Input } from "@Eikochain/components/ui/input"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@Eikochain/components/ui/table"
import { ColumnDef } from "@tanstack/react-table"
import ExportDataButton from "../export-data-dialog/export-data-button"
import Spinner from "../spinner"
import ColumnTogglerDropdownMenu from "./column-toggler-dropdown-menu"
import Paginator from "./paginator"

export interface DataTableProps {
    data: any[];
    isFetching: boolean;
    columns: ColumnDef<any, any>[];
    searchBy?: string;
    showRowCount?: boolean;
    hidePaginationButtons?: boolean;
    showColumnToggler?: boolean;
    exportAvailable?: boolean;
    additionalFilters?: JSX.Element[];
    onRowChange?: (values: any[]) => void;
    enableRowSelection?: (value: any) => boolean;
    onRowClick?: (values: any) => void
}

export default function DataTable({
    data,
    columns,
    isFetching,
    searchBy,
    showRowCount = true,
    hidePaginationButtons = false,
    showColumnToggler = true,
    exportAvailable = false,
    additionalFilters,
    onRowChange,
    enableRowSelection,
    onRowClick
}: DataTableProps) {
    const [sorting, setSorting] = React.useState<SortingState>([])
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
        []
    )
    const [columnVisibility, setColumnVisibility] =
        React.useState<VisibilityState>({})
    const [rowSelection, setRowSelection] = React.useState({})

    const table = useReactTable({
        data,
        columns,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        enableRowSelection,
        state: {
            sorting,
            columnFilters,
            columnVisibility,
            rowSelection,
        },
    })

    useEffect(() => {
        if (onRowChange) onRowChange(table.getSelectedRowModel().flatRows.map((row) => row.original))
    }, [table.getSelectedRowModel().rowsById])

    return (
        <div className="w-full">
            {
                searchBy || exportAvailable || additionalFilters || showColumnToggler ? (
                    <div className="flex justify-between items-center py-2 mb-2">
                        <div className="flex flex-row gap-4">
                            {
                                searchBy ? (
                                    <Input
                                        placeholder="Filter..."
                                        value={(table.getColumn(searchBy)?.getFilterValue() as string) ?? ""}
                                        onChange={(event) =>
                                            table.getColumn(searchBy)?.setFilterValue(event.target.value)
                                        }
                                        className="max-w-sm"
                                    />
                                ) : null
                            }
                            {exportAvailable && (
                                <ExportDataButton
                                    customText={
                                        `Export emissions data${table.getFilteredSelectedRowModel().rows.length ? ` (${table.getFilteredSelectedRowModel().rows.length} selected)` : ""}`
                                    }
                                    disabled={!table.getFilteredSelectedRowModel().rows.length}
                                    disabledText="Please select activities you'd like to export"
                                    activities={table.getFilteredSelectedRowModel().rows?.map((row) => row.original.djangoId)}
                                />
                            )}
                        </div>
                        <div className="flex flex-row gap-4">
                            {additionalFilters}
                            {
                                showColumnToggler ? (
                                    <ColumnTogglerDropdownMenu table={table} />
                                ) : null
                            }
                        </div>
                    </div>
                ) : null
            }
            <div className="rounded-md border">
                <Table>
                    <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <TableHead key={header.id}>
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                        </TableHead>
                                    )
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {table.getRowModel().rows?.length && !isFetching ? (
                            table.getRowModel().rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    data-state={row.getIsSelected() && "selected"}
                                    onClick={() => onRowClick && onRowClick(row.original)}
                                    className={onRowClick && "cursor-pointer"}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell
                                    colSpan={columns.length}
                                    className="h-24 flex-auto"
                                >
                                    <div className="flex justify-center">
                                        {
                                            isFetching ? <Spinner /> : "No results"
                                        }
                                    </div>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            <div className="flex items-center justify-end space-x-2 py-4">
                {
                    showRowCount && !isFetching ? (
                        <div className="flex-1 text-sm text-muted-foreground">
                            {
                                table.getFilteredSelectedRowModel().rows.length ? (
                                    `${table.getFilteredSelectedRowModel().rows.length} of `
                                ) : null
                            }
                            {table.getFilteredRowModel().rows.length} row(s)
                            {table.getFilteredSelectedRowModel().rows.length ? " selected" : null}
                        </div>
                    ) : null
                }
                {
                    !hidePaginationButtons && !isFetching ? (
                        <div className="flex justify-end">
                            <Paginator
                                currentPage={table.getState().pagination.pageIndex + 1}
                                totalPages={table.getPageCount()}
                                onPageChange={(pageNumber) => table.setPageIndex(pageNumber - 1)}
                                showPreviousNext
                            />
                        </div>
                    ) : null
                }
            </div>
        </div>
    )
}
