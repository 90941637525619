import { SupplierInformationResponse } from "@Eikochain/__generated__/graphql";
import DataTable from "../data-table";
import columns from "./columns";

export interface SupplierInformationResponsesTableProps {
  data: SupplierInformationResponse[];
  isFetching: boolean;
  showRowCount?: boolean;
  hidePaginationButtons?: boolean;
}

export default function SupplierInformationResponsesTable({
  data,
  isFetching,
  showRowCount = true,
}: SupplierInformationResponsesTableProps) {
  return (
    <DataTable
      data={data}
      isFetching={isFetching}
      columns={columns}
      showRowCount={showRowCount}
      showColumnToggler={false}
      hidePaginationButtons={false}
      onRowClick={
        (values) => window.open(
          `/app/suppliers/requests/view/${values.supplierInformationRequest.id}/`, '_blank', 'rel=noopener noreferrer'
        )
      }
    />
  )
}
