import { gql, useQuery } from 'urql';

const GetSupplierInformationRequestsQuery = gql`
  query($bySupplierId: String) {
    supplierInformationRequests(bySupplierId: $bySupplierId) {
      id
      status
      createdAt
      forSupplier {
        name
      }
    }
  }
`;

const useGetSupplierInformationRequestsById = (bySupplierId: string) => {
    const result = useQuery({
        query: GetSupplierInformationRequestsQuery,
        variables: {
            bySupplierId
        }
    })
    return result;
}

const useGetSupplierInformationRequests = () => {
    return useQuery({
        query: GetSupplierInformationRequestsQuery,
        variables: {}
    })
}

export { useGetSupplierInformationRequests, useGetSupplierInformationRequestsById };
