import { gql, useQuery } from 'urql';

const GetSuppliersQuery = gql`
  query {
    suppliers {
      id
      name
      contacts {
        id
        name
        email
      }
    }
  }
`;

const useGetSuppliers = () => {
  const result = useQuery({
    query: GetSuppliersQuery,
  })
  return result;
}

export default useGetSuppliers;
