import { SupplierInformationResponseDocument } from "@Eikochain/__generated__/graphql";
import { Button } from "@Eikochain/components/ui/button";
import SupplierInformationRequestStatusBadge from "@Eikochain/components/ui/suppliers-information-requests-table/supplier-information-request-status-badge";
import { Table, TableBody, TableCell, TableRow } from "@Eikochain/components/ui/table";
import useGetSupplierInformationRequestById from "@Eikochain/graphql/hooks/useGetSupplierInformationRequestById";
import BaseLayout from "@Eikochain/layouts/BaseLayout";
import { CheckCheckIcon, DownloadIcon, XIcon } from "lucide-react";
import { Link, useParams } from "react-router-dom";

export default function SupplierInformationRequestDetail() {
  const { id } = useParams<{ id: string }>();
  const [result] = useGetSupplierInformationRequestById(id as string);
  const supplierInformationRequestById = result?.data?.supplierInformationRequestById;
  const breadcrumbs = [
    {
      name: "Suppliers",
      url: "/app/suppliers/"
    },
    {
      name: "Supplier information requests",
      url: "/app/suppliers/requests"
    },
    {
      name: `${supplierInformationRequestById?.id} (Supplier Request)`,
    },
  ]

  return (
    <BaseLayout breadcrumbs={breadcrumbs}>
      <div className="w-full p-4">
        <div className="flex flex-col gap-4 mt-12">
          <div>
            <SupplierInformationRequestStatusBadge status={supplierInformationRequestById?.status} />
          </div>
          <div className="w-full flex flex-row justify-between items-start">
            <h1 className="text-3xl">Supplier request</h1>
          </div>
          <p>Sent to {supplierInformationRequestById?.forSupplier.name}</p>
        </div>
        {
          supplierInformationRequestById?.response ? (
            <div className="flex flex-col gap-4 mt-32">
              <h2>Response details</h2>
              <Table className="max-w-[600px]">
                <TableBody>
                  <TableRow className="hover:bg-inherit border-b-0">
                    <TableCell className="p-0 py-4">Weight (kg)</TableCell>
                    <TableCell className="p-0 py-4">{supplierInformationRequestById?.response?.weight}</TableCell>
                  </TableRow>
                  <TableRow className="hover:bg-inherit border-b-0">
                    <TableCell className="p-0 py-4">Country</TableCell>
                    <TableCell className="p-0 py-4">{supplierInformationRequestById?.response?.country}</TableCell>
                  </TableRow>
                  <TableRow className="hover:bg-inherit border-b-0">
                    <TableCell className="p-0 py-4">Proof of value</TableCell>
                    <TableCell className="p-0 py-4">
                      <div className="flex flex-row gap-4">
                        {
                          supplierInformationRequestById?.response?.documents?.length ? (
                            supplierInformationRequestById?.response?.documents.map(
                              (document: SupplierInformationResponseDocument, index: number) => (
                              <Link to={document.location} target="_blank">
                                <Button variant="outline" icon={<DownloadIcon />}>Document {index + 1}</Button>
                              </Link>
                            ))
                          ) : null
                        }
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow className="hover:bg-inherit border-b-0">
                    <TableCell className="p-0 py-4">Responded at</TableCell>
                    <TableCell className="p-0 py-4">{supplierInformationRequestById?.response.createdAt}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          ) : null
        }
        <div className="flex flex-col gap-4 mt-32">
          <h2>More details</h2>
          <Table className="max-w-[600px]">
            <TableBody>
              <TableRow className="hover:bg-inherit border-b-0">
                <TableCell className="p-0 py-4">Sent by</TableCell>
                <TableCell className="p-0 py-4">{supplierInformationRequestById?.submittedByUser.email}</TableCell>
              </TableRow>
              <TableRow className="hover:bg-inherit border-b-0">
                <TableCell className="p-0 py-4 whitespace-nowrap">Sent to organisation</TableCell>
                <TableCell className="p-0 py-4">{supplierInformationRequestById?.forSupplier.name}</TableCell>
              </TableRow>
              <TableRow className="hover:bg-inherit border-b-0">
                <TableCell className="p-0 py-4">Sent to contact</TableCell>
                <TableCell className="p-0 py-4">
                  {supplierInformationRequestById?.forContact.name} ({supplierInformationRequestById?.forContact.email})
                </TableCell>
              </TableRow>
              <TableRow className="hover:bg-inherit border-b-0">
                <TableCell className="p-0 py-4">Material</TableCell>
                <TableCell className="p-0 py-4">{supplierInformationRequestById?.material}</TableCell>
              </TableRow>
              <TableRow className="hover:bg-inherit border-b-0">
                <TableCell className="p-0 py-4 whitespace-nowrap">Additional information</TableCell>
                <TableCell className="p-0 py-4">{supplierInformationRequestById?.additionalInformation}</TableCell>
              </TableRow>
              <TableRow className="hover:bg-inherit border-b-0">
                <TableCell className="p-0 py-4">Proof required?</TableCell>
                <TableCell className="p-0 py-4">
                  {
                    supplierInformationRequestById?.requireProof === "true" ? (
                      <CheckCheckIcon className="text-emerald-600" />
                    ) : (<XIcon className="text-red-600" />)
                  }
                </TableCell>
              </TableRow>
              <TableRow className="hover:bg-inherit border-b-0">
                <TableCell className="p-0 py-4">Sent at</TableCell>
                <TableCell className="p-0 py-4">{supplierInformationRequestById?.createdAt}</TableCell>
              </TableRow>
              <TableRow className="hover:bg-inherit border-b-0">
                <TableCell className="p-0 py-4">EikoIQ request ID</TableCell>
                <TableCell className="p-0 py-4">{supplierInformationRequestById?.id}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    </BaseLayout>
  )
}
