import { MoreHorizontalIcon, Trash2Icon } from "lucide-react";
import { useState } from "react";
import { Button } from "../button";
import { Dialog } from "../dialog";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../dropdown-menu";
import DeleteSupplierInformationRequestDialogContent from "./delete-supplier-information-request-dialog-content";

interface SupplierInformationRequestActionsDropdownMenuProps {
  supplierInformationRequestId: string;
}

export default function SupplierInformationRequestActionsDropdownMenu({
  supplierInformationRequestId
}: SupplierInformationRequestActionsDropdownMenuProps) {
  const [deleteModal, setDeleteModal] = useState(false)
  return (
    <div onClick={e => e.stopPropagation()}>
      <Dialog open={deleteModal} onOpenChange={setDeleteModal}>
        <DeleteSupplierInformationRequestDialogContent
          supplierInformationRequestId={supplierInformationRequestId}
          onFinish={() => setDeleteModal(false)}
        />
      </Dialog>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" className="ml-auto">
            <MoreHorizontalIcon />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="gap-4">
          <DropdownMenuItem onClick={() => setDeleteModal(true)}>
            <Trash2Icon />
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
