import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "./breadcrumb";
import Spinner from "./spinner";

export interface BreakcrumbProps {
  name: string;
  url?: string;
}

interface WorkspaceControlBarProps {
  breadcrumbs: BreakcrumbProps[];
  description?: string;
}

const WorkspaceControlBar = ({
  breadcrumbs,
  description
}: WorkspaceControlBarProps) => {
  return (
    <div className="flex flex-col gap-2 w-full px-4 py-2">
      <Breadcrumb>
        <BreadcrumbList>
          {
            breadcrumbs.map((breadcrumb, index) => {
              const breadcrumbNameDisplay = breadcrumb.name !== "undefined" ? breadcrumb.name : <Spinner />
              return (
                <div className="inline-flex items-center gap-2" key={index}>
                  <BreadcrumbItem>
                    {
                      breadcrumb.url ? (
                        <BreadcrumbLink href={breadcrumb.url}>{breadcrumbNameDisplay}</BreadcrumbLink>
                      ) : (<BreadcrumbPage>{breadcrumbNameDisplay}</BreadcrumbPage>)
                    }
                  </BreadcrumbItem>
                  {index !== breadcrumbs.length - 1 ? <BreadcrumbSeparator /> : null}
                </div>
              )
            })
          }
        </BreadcrumbList>
      </Breadcrumb>
      {description ? <h4>{description}</h4> : null}
    </div>
  );
};

export default WorkspaceControlBar;
