import { Control } from "react-hook-form";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../form";
import { Input } from "../../input";

interface CompanyNameFieldProps {
  control: Control<any>;
}

export default function CompanyNameField({
  control,
}: CompanyNameFieldProps) {
  return (
    <FormField
      control={control}
      name="companyName"
      render={({ field }) => {
        return (
          <FormItem>
            <FormLabel>Company</FormLabel>
            <FormControl>
              <Input placeholder="Supply Co" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )
      }}
    />
  )
}
