import { setup } from "goober";
import { createGlobalStyles } from 'goober/global';
import React, { ReactNode } from "react";
import { modernEraFamily } from "./constants";

setup(React.createElement);

const GlobalStyles = createGlobalStyles`
  html {
    box-sizing: border-box;
  }
  *,
  *:before,
  *:after {
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    text-decoration: none;
    position: relative;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    font-family: ${modernEraFamily};
  }

  a {
    display: flex;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-weight: 400;
    margin: 0 !important;
  }

  /* Make clicks pass-through */
  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: #c41d7f;

    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;

    width: 100%;
    height: 4px;
  }

  /* Fancy blur effect */
  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 12px #c41d7f, 0 0 6px #c41d7f;
    opacity: 1;

    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
  }
`;

const GlobalTheme = ({
  children
}: { children: ReactNode }) => (
  <>
    <GlobalStyles />
    {children}
  </>
);

export default GlobalTheme;
