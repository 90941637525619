import { Activity, EmissionFactorInputParameter, EmissionFactorSource } from "@Eikochain/__generated__/graphql";
import { LocationOption } from "@Eikochain/api/types";
import { fieldValueOptionsForShippingMethod } from "@Eikochain/constants";
import { CreateOrUpdateActivityMutation } from "@Eikochain/graphql/hooks/useCreateOrUpdateActivity";
import { ActivityTypeOption, ScopeOption } from "@Eikochain/types";
import { cn } from "@Eikochain/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { ScanEyeIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "urql";
import { Button } from "../button";
import { DialogClose, DialogContent, DialogContentScrollable, DialogHeader, DialogTitle } from "../dialog";
import { Form } from "../form";
import DataExplorerAddon from "./data-explorer-addon";
import ActivityAndEmissionFactorForm from "./forms/activity-and-emission-factor-form";
import SourcesAndParametersForm from "./forms/sources-and-parameters-form";
import onSubmit from "./on-submit";
import { formSchema } from "./schema";
import { ActivityTypesForInterface, getActivityTypesForInterface } from "./utils";

type CreateOrUpdateActivityModalProps = {
  activityTypes?: ActivityTypesForInterface
  onFinish: () => void;
  instance?: Activity;
  defaultActivityType?: ActivityTypeOption;
  productId?: string;
}

export default function CreateOrUpdateActivityModal({
  activityTypes = "product",
  onFinish,
  instance,
  defaultActivityType,
  productId,
}: CreateOrUpdateActivityModalProps) {
  const [showDataExplorer, setShowDataExplorer] = useState(false)
  const [sourcesAvailable, setSourcesAvailable] = useState<EmissionFactorSource[]>([]);
  const [selectedParameter, setSelectedParameter] = useState<EmissionFactorInputParameter>();

  const form = useForm({
    resolver: yupResolver(formSchema(!productId)),
    defaultValues: {
      name: instance?.name,
      activityType: (instance?.activityType.toLowerCase() ?? defaultActivityType) as ActivityTypeOption,
      scope: instance?.scope?.value as ScopeOption,
      transportMethod: instance?.activityType.toLowerCase() === "transport" ? (
        instance?.transportMethod ?? fieldValueOptionsForShippingMethod[0]?.value
      ) : undefined,
      material: instance?.material as string,
      process: instance?.process as string,
      weight: instance?.weight as number,
      spend: instance?.spend as number,
      region: instance?.region,
      energy: instance?.energy as number,
      fuelType: instance?.fuelType as string,
      serviceType: instance?.serviceType as string,
      goodsType: instance?.goodsType as string,
      disposalMethod: instance?.disposalMethod as string,
      origin: instance?.route?.origin as LocationOption,
      destination: instance?.route?.destination as LocationOption,
      distance: instance?.route?.distance as number,
      currency: instance?.currency ?? {currencyCode: "GBP", currencyName: "Pound"},
    },
    context: {
      inputParameters: selectedParameter?.name,
    }
  })

  const [mutationResult, executeMutation] = useMutation(CreateOrUpdateActivityMutation);
  const availableActivityTypes = getActivityTypesForInterface(activityTypes)

  useEffect(() => {
    if (instance) {
      form.clearErrors()
    } else {
      form.reset()
    }
  }, [instance]);

  function onSuccess() {
    form.reset();
    onFinish()
  }

  return (
    <DialogContent className={cn(
      showDataExplorer ? "md:w-auto md:max-w-[1100px]" : "md:w-auto md:max-w-[700px]"
    )}>
      <DialogHeader>
        <div className="w-full flex flex-row gap-4 justify-between items-center">
          <DialogTitle>{instance ? "Editing activity" : "Create a new activity"}</DialogTitle>
          <Button
            className="p-0"
            variant="link"
            icon={<ScanEyeIcon />}
            onClick={() => setShowDataExplorer(!showDataExplorer)}
          >
            {showDataExplorer ? "Hide" : "Show"} data explorer
          </Button>
        </div>
      </DialogHeader>
      <div className="flex flex-row overflow-y-scroll">
        <DialogContentScrollable className="shrink-0 w-full max-w-[600px]">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit((values) => onSubmit(
                values, executeMutation, onSuccess, productId, instance
              ))}
              className="flex flex-col space-y-6 w-full h-full justify-between"
            >
              <div className="flex flex-col space-y-6">
                <ActivityAndEmissionFactorForm
                  control={form.control}
                  setValue={form.setValue}
                  resetField={form.resetField}
                  activityTypes={availableActivityTypes}
                  instance={instance}
                  setSourcesAvailable={setSourcesAvailable}
                  displayScopeField={activityTypes === "company"}
                />
                <SourcesAndParametersForm
                  activity={instance}
                  control={form.control}
                  sources={sourcesAvailable}
                  setSelectedParameter={setSelectedParameter}
                />
              </div>
              <div className="flex flex-row justify-end">
                <div className="flex flex-row gap-2">
                  <Button type="submit" variant="fun" loading={mutationResult.fetching}>Confirm</Button>
                  <DialogClose asChild>
                    <Button variant="destructive" type="button">
                      Cancel
                    </Button>
                  </DialogClose>
                </div>
              </div>
            </form>
          </Form>
        </DialogContentScrollable>
        <div
          className={cn(
            "p-2 ml-4 w-[400px] border-2 border-slate-200 bg-slate-100 rounded-md",
            showDataExplorer ? "visible" : "hidden"
          )}
        >
          <DataExplorerAddon />
        </div>
      </div>
    </DialogContent>
  );
};
