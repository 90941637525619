import { SupplierInformationResponse } from "@Eikochain/__generated__/graphql";
import { Button } from "@Eikochain/components/ui/button";
import Logo from "@Eikochain/components/ui/logo";
import Spinner from "@Eikochain/components/ui/spinner";
import SupplierInformationRequestForm from "@Eikochain/components/ui/supplier-information-request-form";
import useGetSupplierInformationRequestById from "@Eikochain/graphql/hooks/useGetSupplierInformationRequestById";
import { CheckCheckIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

export default function SupplierInformationRequestPage() {
  const [valuesSent, setValuesSent] = useState<SupplierInformationResponse>()
  const [previouslySent, setPreviouslySent] = useState(false)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const supplierRequestId = searchParams.get("id") as string;
  if (!supplierRequestId) navigate("/404/")
  const [result] = useGetSupplierInformationRequestById(supplierRequestId)
  if (result.error) navigate("/404/");

  const supplierInformationRequestById = result?.data?.supplierInformationRequestById;
  useEffect(() => {
    if (supplierInformationRequestById?.response) {
      setPreviouslySent(true)
    }
  }, [supplierInformationRequestById?.response])

  return (
    <div className="w-full flex flex-col items-center">
      <div className="flex flex-col gap-12 items-center max-w-[800px] p-16">
        <div className="flex flex-col items-center gap-2 max-w-[180px]">
          <Logo />
          <h3 className="text-indigo-600">SUPPLIER PORTAL</h3>
        </div>
        {
          result.fetching ? <Spinner /> : (
            previouslySent ? (
              <div className="flex flex-col items-center justify-center gap-4">
                <div className="flex items-center justify-center rounded-full p-2 bg-emerald-100">
                  <div className="flex items-center justify-center rounded-full h-16 w-16 bg-emerald-700">
                    <CheckCheckIcon className="text-white" />
                  </div>
                </div>
                <h3 className="text-center">This information has already been submitted to {supplierInformationRequestById?.submittedByOrganization?.name}</h3>
                <Link to="https://eikoiq.com" target="_blank">
                  <Button variant="fun">
                    Get started tracking your own emissions with EikoIQ
                  </Button>
                </Link>
              </div>
            ) : (
              valuesSent ? (
                <div className="flex flex-col items-center justify-center gap-4">
                  <div className="flex items-center justify-center rounded-full p-2 bg-emerald-100">
                    <div className="flex items-center justify-center rounded-full h-16 w-16 bg-emerald-700">
                      <CheckCheckIcon className="text-white" />
                    </div>
                  </div>
                  <h3 className="text-center">Sent response for {supplierInformationRequestById?.material} to {supplierInformationRequestById?.submittedByOrganization?.name}</h3>
                  <Link to="https://eikoiq.com" target="_blank">
                    <Button variant="fun">
                      Get started tracking your own emissions with EikoIQ
                    </Button>
                  </Link>
                </div>
              ) : (
                <>
                  <div className="text-center">
                    <p className="text-balance">
                      One of your customers, {supplierInformationRequestById?.submittedByOrganization?.name}, has requested emissions data on a product they purchase.
                      If you aren't {supplierInformationRequestById?.forContact?.name} at {supplierInformationRequestById?.forSupplier?.name} then kindly ignore this request or pass it on to them at your company.
                    </p>
                  </div>
                  <div className="flex flex-col w-full">
                    <p className="text-left">The product is <span className="text-indigo-600">{supplierInformationRequestById?.material}</span>.</p>
                    {
                      supplierInformationRequestById?.additionalInformation && (
                        <p className="text-left">They have provided the following additional information <span className="text-indigo-600">{supplierInformationRequestById?.additionalInformation}</span>.</p>
                      )
                    }
                    <p>Please fill out the fields below.</p>
                  </div>
                  <div className="w-full">
                    <SupplierInformationRequestForm
                      supplierRequest={supplierInformationRequestById}
                      supplierName={supplierInformationRequestById?.forSupplier?.name}
                      onSuccess={(v) => setValuesSent(v)}
                    />
                  </div>
                </>
              )
            )
          )
        }
      </div>
    </div>
  )
}
