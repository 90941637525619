import { SupplierInformationRequest } from "@Eikochain/__generated__/graphql"
import { CreateSupplierInformationRequestMutation } from "@Eikochain/graphql/hooks/mutations/create-supplier-information-request"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { toast } from "sonner"
import { useMutation } from "urql"
import { InferType } from "yup"
import { Button } from "../../button"
import { Form } from "../../form"
import SupplierInfoForm from "./forms/supplier-info-form"
import { addSupplierFormSchema } from "./schema"

interface AddSupplierInformationRequestFormProps {
    onSuccess: (values: SupplierInformationRequest) => void;
}

export default function AddSupplierInformationRequestForm({
    onSuccess
}: AddSupplierInformationRequestFormProps) {
    const [mutationResult, executeMutation] = useMutation(CreateSupplierInformationRequestMutation);
    const form = useForm({
        resolver: yupResolver(addSupplierFormSchema),
        defaultValues: {
            supplier: undefined,
            contact: undefined,
            supplierInformationRequestType: "material_weight",
            additionalInformation: "",
            material: undefined,
            requireProof: false,
        }
    })

    function onSubmit(values: InferType<typeof addSupplierFormSchema>) {
        executeMutation({
            input: {
                additionalInformation: values.additionalInformation,
                supplierInformationRequestType: values.supplierInformationRequestType,
                material: values.material,
                requireProof: values.requireProof,
                contactId: values.contact.id,
                supplierId: values.supplier.id
            }
        }).then((result) => {
            if (result.data?.createSupplierInformationRequest?.supplierRequest) {
                toast.success("Sent supplier information request")
                onSuccess(result.data?.createSupplierInformationRequest?.supplierRequest)
            } else {
                toast.error("An error occurred, we'll look into it")
            }
        });
    };

    return (
        <Form {...form}>
            <form
                className="space-y-6"
                onSubmit={form.handleSubmit(onSubmit)}
            >
                <SupplierInfoForm
                    control={form.control}
                    setValue={form.setValue}
                />
                <div className="flex flex-row gap-2 justify-end">
                    <Button
                        type="submit"
                        loading={mutationResult?.fetching}
                        disabled={mutationResult?.data}
                    >
                        Send request
                    </Button>
                </div>
            </form>
        </Form>
    )
}
