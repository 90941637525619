import { gql } from 'urql';

export const CreateSupplierInformationRequestMutation = gql`
  mutation CreateSupplierInformationRequest($input: CreateSupplierInformationRequestInput!) {
    createSupplierInformationRequest(input: $input) {
      ... on CreateSupplierInformationRequestSuccess {
        supplierRequest {
          id
          forContact {
            email
          }
          forSupplier {
            name
          }
        }
      }
      ... on CreateSupplierInformationRequestErrors {
        errors
      }
    }
  }
`;
