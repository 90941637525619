import useGetSupplierInformationResponses from "@Eikochain/graphql/hooks/useGetSupplierInformationResponses";
import SupplierInformationResponsesTable from ".";

export default function SupplierInformationResponsesTableWithData() {
  const [result] = useGetSupplierInformationResponses();
  return (
    <SupplierInformationResponsesTable
      data={result?.data?.supplierInformationResponses ?? []}
      isFetching={result.fetching}
      showRowCount={true}
    />
  )
}
