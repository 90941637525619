import { Control } from "react-hook-form";
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "../../form";
import { Input } from "../../input";

interface DocumentUploadFieldProps {
  control: Control<any>;
  required?: boolean;
}

export default function DocumentUploadField({
  control,
  required = false,
}: DocumentUploadFieldProps) {
  return (
    <FormField
      control={control}
      name="documentUpload"
      render={({ field: { value, onChange, ...fieldProps } }) => {
        return (
          <FormItem>
            <FormLabel>Proof of value</FormLabel>
            <FormDescription>
              Here you can upload a certificate, invoice or other document that verifies the weight you've uploaded above.
              {required ? "This has been marked as required by the customer" : null}
            </FormDescription>
            <FormControl>
              <Input
                {...fieldProps}
                type="file"
                accept="image/png, image/jpeg, application/pdf"
                onChange={(event) =>
                  onChange(event.target.files && event.target.files[0])
                }
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )
      }}
    />
  )
}
