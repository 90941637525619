import { SupplierInformationRequest } from "@Eikochain/__generated__/graphql";
import { CoreRow } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import DataTable from "../data-table";
import columns from "./columns";

interface SupplierInformationRequestsTableProps {
  data: SupplierInformationRequest[];
  isFetching: boolean;
  showRowCount?: boolean;
  hidePaginationButtons?: boolean;
}

export default function SupplierInformationRequestsTable({
  data,
  isFetching,
  showRowCount = true,
}: SupplierInformationRequestsTableProps) {
  const navigate = useNavigate();
  return (
    <DataTable
      data={data}
      isFetching={isFetching}
      columns={columns}
      showRowCount={showRowCount}
      showColumnToggler={false}
      hidePaginationButtons={false}
      onRowClick={
        (values: CoreRow<SupplierInformationRequest>) => navigate(`/app/suppliers/requests/view/${values.id}`)
      }
    />
  )
}
