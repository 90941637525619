import { Control } from "react-hook-form";
import { FormField } from "../../form";
import WeightFormItem from "../../weight-form-item";

interface WeightPerUnitSpendFieldProps {
  control: Control<any>;
}

export default function WeightPerUnitSpendField({
  control
}: WeightPerUnitSpendFieldProps) {
  return (
    <FormField
      control={control}
      name="weightPerUnitSpend"
      render={({ field }) => (
        <WeightFormItem
          field={field}
          description="Please enter the weight of product produced per British Pound spent by the customer"
        />
      )}
    />
  )
}
