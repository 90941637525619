import { SupplierInformationRequest, SupplierInformationResponse } from "@Eikochain/__generated__/graphql"
import { CreateSupplierInformationResponseMutation } from "@Eikochain/graphql/hooks/mutations/create-supplier-information-response"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { useMutation } from "urql"
import { Button } from "../button"
import { Form } from "../form"
import CompanyNameField from "./fields/company-name-field"
import CountryField from "./fields/country-field"
import DocumentUploadField from "./fields/document-upload-field"
import WeightPerUnitSpendField from "./fields/weight-per-unit-spend-field"
import onSubmit from "./on-submit"
import { supplierInformationRequestFormSchema } from "./schema"

interface SupplierInformationRequestFormProps {
  supplierRequest: SupplierInformationRequest,
  supplierName: string,
  onSuccess: (values: SupplierInformationResponse) => void,
}

export default function SupplierInformationRequestForm({
  supplierRequest,
  supplierName,
  onSuccess,
}: SupplierInformationRequestFormProps) {
  const [mutationResult, executeMutation] = useMutation(CreateSupplierInformationResponseMutation)
  const requireProof = supplierRequest.requireProof === "true"
  const form = useForm({
    resolver: yupResolver(supplierInformationRequestFormSchema),
    defaultValues: {
      country: "",
      companyName: supplierName,
      weightPerUnitSpend: 0,
      documentUpload: undefined,
    },
    context: { requireProof }
  })

  return (
    <Form {...form}>
      <form
        className="flex flex-col space-y-6"
        onSubmit={form.handleSubmit((values) => onSubmit(
          supplierRequest.id, values, executeMutation, onSuccess
        ))}
      >
        <CompanyNameField control={form.control} />
        <CountryField control={form.control} setValue={form.setValue} />
        <WeightPerUnitSpendField control={form.control} />
        <DocumentUploadField control={form.control} required={requireProof} />
        <Button loading={mutationResult.fetching} type="submit">Submit</Button>
      </form>
    </Form>
  )
}
