import { gql, useQuery } from 'urql';

const GetSupplierByIdQuery = gql`
  query($id: String!) {
    supplierById(id: $id) {
      id
      name
    }
  }
`;

const useGetSupplierById = (id?: string) => {
    if (!id) {
        return [null];
    }

    const result = useQuery({
        query: GetSupplierByIdQuery,
        variables: {
            id
        }
    })
    return result;
}

export default useGetSupplierById;
